<template>
  <app-page-layout :title="$t('hr.training.training_detail')" icon="tw-hr-cost" @close="leavePage">
    <template #content>
      <v-row>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.training.plan") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2 d-flex align-center">
            <v-icon class="mr-2" :color="$helpers.get(trainingPlan, 'color', 'gray')">mdi-record</v-icon>
            {{ $helpers.get(trainingPlan, "name") || "-" }}
          </span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.training.name") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $helpers.get(trainingPlan, "training.name") || "-" }}
          </span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.training.type") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $t(`hr.training.training_type.${$helpers.get(trainingPlan, "trainingType")}`) || "-" }}
          </span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.training.organizer") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{
              `${$helpers.get(trainingPlan, "organiser.firstName", "-")} ${$helpers.get(
                trainingPlan,
                "organiser.lastName",
                ""
              )}`
            }}
          </span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.training.lecturer") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{
              $helpers.get(trainingPlan, "externalLecturer")
                ? $helpers.get(trainingPlan, "externalLecturer")
                : `${$helpers.get(trainingPlan, "lecturer.firstName", "-")} ${$helpers.get(
                    trainingPlan,
                    "lecturer.lastName",
                    ""
                  )}`
            }}
          </span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.training.location") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $helpers.get(trainingPlan, "location") || "-" }}
          </span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.training.terminal") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $helpers.get(trainingPlan, "terminal.name") || "-" }}
          </span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.training.sessions") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <v-list v-else>
            <v-list-item v-for="session in $helpers.get(trainingPlan, 'trainingSessions', [])" :key="session.id">
              <v-list-item-icon>
                <v-icon color="teal">mdi-clock-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ session.beginDateTime | formattedDateTimeMinute }} -
                  {{ session.endDateTime | formattedDateTimeMinute }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.training.description") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $helpers.get(trainingPlan, "description") || "-" }}
          </span>
        </v-col>
      </v-row>
    </template>
  </app-page-layout>
</template>

<script>
  import { TRAINING_DETAIL } from "../../../query";

  export default {
    name: "TrainingDetail",
    components: {
      AppPageFooter: () => import("@/layouts/app-page-footer")
    },
    props: {
      id: [String, Number]
    },
    data: () => ({
      loading: false,
      trainingPlan: null
    }),
    watch: {
      id: {
        handler() {
          this.fetchSelectedItemById();
        },
        immediate: true
      }
    },
    methods: {
      leavePage() {
        this.$emit("closed:detail");
        this.$router.replace({ name: "trainings" });
      },
      async fetchSelectedItemById() {
        this.loading = true;
        await this.$apollo
          .query({
            query: TRAINING_DETAIL,
            variables: {
              id: this.$helpers.decodeId(this.id)
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.trainingPlan = data.trainingPlan;
              this.$emit("detail-loaded:success", data.trainingPlan);
            }
          })
          .catch(() => {
            this.$emit("detail-loaded:error");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped></style>
